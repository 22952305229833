:root {
    --color-primary: #6c30cb;
    --color-secondary: #595ec7;
    --color-terciary: #00c3a5;

    --color-error: #ef5350;
    --color-warning: #ff9800;
    --color-info: #03a9f4;
    --color-success: #4caf50;

    --color-primary-darker: #15183a;
    --bg-main: rgb(238, 241, 245);

    --color-gradient-1: linear-gradient(
        90deg,
        rgba(53, 52, 133, 1) 0%,
        rgba(110, 58, 142, 1) 35%,
        rgba(104, 175, 225, 1) 100%
    );
    --color-gradient-2: linear-gradient(
        to top right,
        rgb(38, 38, 87) 5%,
        rgb(113, 58, 141) 50%,
        rgb(85, 171, 223) 100%
    );
    --color-gradient-3: linear-gradient(
        to left right,
        rgb(38, 38, 87) 10%,
        rgb(85, 171, 223) 50%,
        rgb(38, 38, 87) 100%
    );
    --color-gradient-4: linear-gradient(to top, #595ec7, #68afe1, #595ec7);

    --disabled-color: linear-gradient(rgb(175, 171, 171), rgb(175, 171, 171));

    --color-white: white;
    --color-black: black;
    --color-gray: rgb(183, 183, 183);
    --color-gray-low: #ccc;
}
