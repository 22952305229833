:root {
    --nav-height: 10vh;
    --body-height: 90vh;
    --footer-height: 7vh;

    --font-small: 12px;
    --font-normal: 16px;
    --font-medium: 20px;
    --font-large: 25px;
    --font-extra-large: 30px;
    --font-x-extra-large: 40px;

    --border-radius-small: 10px;
    --border-radius-normal: 15px;
    --border-radius-medium: 20px;
    --border-radius-large: 30px;
    --border-radius-larger: 40px;
    --border-radius-extra-large: 50px;
}
