.a4Nfeq_container {
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: #f0f8ff93;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

._8ZbzdG_container {
  background-color: var(--bg-main);
}

._8ZbzdG_container ._8ZbzdG_content {
  flex-direction: row;
  gap: 20px;
  display: flex;
}

@media (max-width: 767px) {
  ._8ZbzdG_container ._8ZbzdG_content {
    flex-direction: column;
  }
}

.QVu3YW_container {
  height: 250px;
  width: 350px;
  background-color: #f5f5f5;
  border-radius: 10px;
  flex-direction: row;
  display: flex;
  overflow: hidden;
}

.QVu3YW_container .QVu3YW_map {
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.QVu3YW_container .QVu3YW_data {
  width: 50%;
  background-color: var(--color-info);
  padding: 0 10px;
  font-size: 12px;
}

.QVu3YW_container .QVu3YW_data .QVu3YW_statusBadge {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.QVu3YW_container .QVu3YW_data .QVu3YW_statusBadge .QVu3YW_badge {
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  border-radius: 0 5px 0 20px;
  padding: 5px;
  font-weight: bolder;
  position: relative;
  left: 10px;
}

.QVu3YW_container .QVu3YW_data .QVu3YW_statusBadge .QVu3YW_badge__low {
  background-color: #07e207;
}

.QVu3YW_container .QVu3YW_data .QVu3YW_statusBadge .QVu3YW_badge__medium {
  background-color: #ffd900;
}

.QVu3YW_container .QVu3YW_data .QVu3YW_statusBadge .QVu3YW_badge__high {
  background-color: #ff9100;
}

.QVu3YW_container .QVu3YW_data .QVu3YW_statusBadge .QVu3YW_badge__urgent {
  background-color: tomato;
}

.QVu3YW_container .QVu3YW_data .QVu3YW_status {
  width: 80px;
  height: 20px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  display: flex;
}

.QVu3YW_container .QVu3YW_data .QVu3YW_title {
  margin-bottom: 5px;
  font-weight: bolder;
  text-decoration: underline;
}

.QVu3YW_container .QVu3YW_data .QVu3YW_label {
  color: #fff;
}

.QVu3YW_container .QVu3YW_data .QVu3YW_text {
  color: #fff;
  max-width: 280px;
  max-height: 30px;
  overflow-wrap: break-word;
  margin-bottom: 5px;
  font-weight: bolder;
  overflow-y: hidden;
}

.n2B-bq_container {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-image: url("bg.e5923299.jpg");
  background-size: cover;
}

.n2B-bq_layer {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: #ffffff56;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.n2B-bq_content {
  width: 90%;
  margin-top: 50px;
}

.n2B-bq_content .n2B-bq_header {
  max-width: 400px;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 25px;
  display: flex;
}

.n2B-bq_content .n2B-bq_header .n2B-bq_option {
  border: none;
  border: 2px solid var(--color-info);
  color: var(--color-info);
  cursor: pointer;
  background-color: #fff;
  border-radius: 20px;
  padding: 5px 10px;
}

.n2B-bq_content .n2B-bq_header .n2B-bq_option__active {
  background-color: var(--color-info);
  color: #fff;
}

.n2B-bq_content .n2B-bq_cards {
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  margin-bottom: 50px;
  display: grid;
}

.n2B-bq_buttons {
  flex-direction: row;
  justify-content: end;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.n2B-bq_buttons__close {
  color: #fff;
  cursor: pointer;
  background-color: salmon;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 7px;
  font-weight: bolder;
}

.n2B-bq_buttons__close:hover {
  background-color: red;
}

.g9NzCa_container {
  min-height: 100vh;
  width: 100%;
}

.g9NzCa_container .g9NzCa_header {
  height: 30vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.g9NzCa_container .g9NzCa_content {
  min-height: 70vh;
  background-color: var(--color-info);
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 5px;
  display: flex;
}

.g9NzCa_container .g9NzCa_content .g9NzCa_body {
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  font-size: 18px;
  display: flex;
}

.g9NzCa_container .g9NzCa_content .g9NzCa_button {
  width: 300px;
  border: none;
  border: 2px solid var(--color-info);
  color: var(--color-info);
  cursor: pointer;
  border-radius: 20px;
  align-self: center;
  margin-bottom: 20px;
  padding: 10px;
  font-weight: bolder;
}

.g9NzCa_container .g9NzCa_content .g9NzCa_row {
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  display: flex;
}

.g9NzCa_container .g9NzCa_content .g9NzCa_column {
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  display: flex;
}

.g9NzCa_container .g9NzCa_content .g9NzCa_title {
  margin-bottom: 10px;
  font-weight: bolder;
  text-decoration: underline;
}

.g9NzCa_container .g9NzCa_content .g9NzCa_label {
  color: #fff;
}

.g9NzCa_container .g9NzCa_content .g9NzCa_text {
  color: #fff;
  max-width: 350px;
  overflow-wrap: break-word;
  font-weight: bolder;
}

.g9NzCa_selectorState {
  width: 300px;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.g9NzCa_selectorState__option {
  width: 100%;
  color: #777;
  text-align: center;
  cursor: pointer;
  background-color: #f5f5f5;
  border: 1px solid #8888886b;
  border-radius: 3px;
  padding: 3px;
}

.g9NzCa_selectorState__option--active {
  color: var(--color-info);
  zoom: 1.1;
  background-color: #fff;
  padding: 5px;
}

.g9NzCa_buttonContainer {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 20px 0;
  display: flex;
}

.g9NzCa_updateButton {
  background-color: var(--color-info);
  color: #fff;
  width: 130px;
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 20px;
  align-self: center;
  margin-top: 20px;
  padding: 5px;
  font-weight: bolder;
}

@media (max-width: 480px) {
  .g9NzCa_container .g9NzCa_content .g9NzCa_text {
    max-width: 200px;
    overflow-wrap: break-word;
  }

  .g9NzCa_buttonContainer {
    flex-direction: column;
    gap: 5px;
  }
}

.CM0Nmq_button {
  color: var(--color-white);
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.CM0Nmq_button .CM0Nmq_icon {
  width: 30px;
  align-self: center;
}

.CM0Nmq_button__primary {
  border: 2px solid var(--color-gradient-1);
  background-image: var(--color-gradient-1);
  color: var(--color-white);
}

.CM0Nmq_button__primary:hover, .CM0Nmq_button__primary:focus {
  opacity: .9;
}

.CM0Nmq_button__primaryOutLine {
  border: 2px solid var(--color-primary);
  background-color: var(--color-white);
  color: var(--color-primary);
}

.CM0Nmq_button__primaryOutLine:hover, .CM0Nmq_button__primaryOutLine:focus {
  opacity: .9;
  border: 2px solid var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-white);
}

.CM0Nmq_button__secondary {
  border: 2px solid var(--color-black);
  background-color: var(--color-black);
  color: var(--color-white);
}

.CM0Nmq_button__secondary:hover, .CM0Nmq_button__secondary:focus {
  opacity: .9;
}

.CM0Nmq_button__secondaryOutLine {
  border: 2px solid var(--color-secondary);
  background-color: var(--color-white);
  color: var(--color-secondary);
}

.CM0Nmq_button__secondaryOutLine:hover, .CM0Nmq_button__secondaryOutLine:focus {
  opacity: .9;
  border: 2px solid var(--color-secondary);
  background-color: var(--color-secondary);
  color: var(--color-white);
}

.CM0Nmq_button__terciary {
  border: 2px solid var(--color-terciary);
  background-color: var(--color-terciary);
  color: var(--color-white);
}

.CM0Nmq_button__terciary:hover, .CM0Nmq_button__terciary:focus {
  opacity: .9;
}

.CM0Nmq_button__terciaryOutLine {
  border: 2px solid var(--color-terciary);
  background-color: var(--color-white);
  color: var(--color-terciary);
}

.CM0Nmq_button__terciaryOutLine:hover, .CM0Nmq_button__terciaryOutLine:focus {
  opacity: .9;
  border: 2px solid var(--color-terciary);
  background-color: var(--color-terciary);
  color: var(--color-white);
}

.CM0Nmq_button__success {
  border: 2px solid var(--color-success);
  background-color: var(--color-success);
  color: var(--color-white);
}

.CM0Nmq_button__success:hover, .CM0Nmq_button__success:focus {
  opacity: .9;
}

.CM0Nmq_button__successOutLine {
  border: 2px solid var(--color-success);
  background-color: var(--color-white);
  color: var(--color-success);
}

.CM0Nmq_button__successOutLine:hover, .CM0Nmq_button__successOutLine:focus {
  opacity: .9;
  border: 2px solid var(--color-success);
  background-color: var(--color-success);
  color: var(--color-white);
}

.CM0Nmq_button__info {
  border: 2px solid var(--color-info);
  background-color: var(--color-info);
  color: var(--color-white);
}

.CM0Nmq_button__info:hover, .CM0Nmq_button__info:focus {
  opacity: .9;
}

.CM0Nmq_button__infoOutLine {
  border: 2px solid var(--color-info);
  background-color: var(--color-white);
  color: var(--color-info);
}

.CM0Nmq_button__infoOutLine:hover, .CM0Nmq_button__infoOutLine:focus {
  opacity: .9;
  border: 2px solid var(--color-info);
  background-color: var(--color-info);
  color: var(--color-white);
}

.CM0Nmq_button__warning {
  border: 2px solid var(--color-warning);
  background-color: var(--color-warning);
  color: var(--color-white);
}

.CM0Nmq_button__warning:hover, .CM0Nmq_button__warning:focus {
  opacity: .9;
}

.CM0Nmq_button__warningOutLine {
  border: 2px solid var(--color-warning);
  background-color: var(--color-white);
  color: var(--color-warning);
}

.CM0Nmq_button__warningOutLine:hover, .CM0Nmq_button__warningOutLine:focus {
  opacity: .9;
  border: 2px solid var(--color-warning);
  background-color: var(--color-warning);
  color: var(--color-white);
}

.CM0Nmq_button__error {
  border: 2px solid var(--color-error);
  background-color: var(--color-error);
  color: var(--color-white);
}

.CM0Nmq_button__error:hover, .CM0Nmq_button__error:focus {
  opacity: .7;
}

.CM0Nmq_button__errorOutLine {
  border: 2px solid var(--color-error);
  background-color: var(--color-white);
  color: var(--color-error);
}

.CM0Nmq_button__errorOutLine:hover, .CM0Nmq_button__errorOutLine:focus {
  opacity: .9;
  border: 2px solid var(--color-error);
  background-color: var(--color-white);
  color: var(--color-error);
}

.CM0Nmq_button__disabled {
  opacity: .5;
  cursor: not-allowed;
  pointer-events: none;
}

.CM0Nmq_button__xsmall {
  padding: .2rem;
  font-size: .7rem;
}

.CM0Nmq_button__small {
  padding: .25rem;
  font-size: 1rem;
}

.CM0Nmq_button__medium {
  padding: .3rem;
  font-size: 1.2rem;
}

.CM0Nmq_button__large {
  padding: .35rem;
  font-size: 1.5rem;
}

.CM0Nmq_button__xlarge {
  padding: .33rem;
  font-size: 1.7rem;
}

.CM0Nmq_button__adaptable {
  padding: .4rem;
  font-size: 2rem;
}

.C4YQqa_container {
  border: 1px solid var(--color-info);
  background-color: #fff;
  border-radius: 15px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px;
  display: flex;
}

.C4YQqa_containerIcon {
  width: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.C4YQqa_icon {
  width: 28px;
  height: 28px;
  color: var(--color-info);
}

.C4YQqa_input {
  color: var(--color-info);
  width: 100%;
  border: none;
  margin: 10px;
  font-size: 24px;
}

.C4YQqa_input::placeholder {
  color: var(--color-info);
  opacity: 1;
}

.C4YQqa_input:focus {
  outline: none;
}

@media (max-width: 480px) {
  .C4YQqa_container {
    width: 90%;
  }

  .C4YQqa_icon {
    width: 40px;
    align-self: center;
  }

  .C4YQqa_input {
    width: 100%;
  }
}

.Y5oDPG_inputContent {
  border: 1px solid var(--color-info);
  background-color: #fff;
  border-radius: 15px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px;
  display: flex;
}

.Y5oDPG_inputContent .Y5oDPG_input {
  color: var(--color-info);
  width: 100%;
  border: none;
  margin: 10px;
  font-size: 24px;
}

.Y5oDPG_inputContent .Y5oDPG_containerIcon {
  width: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Y5oDPG_inputContent .Y5oDPG_icon {
  width: 28px;
  height: 28px;
  cursor: pointer;
  color: var(--color-info);
}

.Y5oDPG_inputContent .Y5oDPG_input::placeholder {
  color: var(--color-info);
  opacity: 1;
}

.Y5oDPG_inputContent .Y5oDPG_input:focus {
  outline: none;
}

.r1P2za_container {
  height: 100vh;
  width: 100%;
  background-image: url("bg.e5923299.jpg");
  background-position: center;
  background-size: cover;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.r1P2za_container .r1P2za_titleContainer {
  width: 100%;
  min-height: 150px;
  opacity: .7;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  display: flex;
}

.r1P2za_container .r1P2za_titleContainer .r1P2za_title {
  text-align: center;
  width: 80%;
  color: #fff;
  font-size: 28px;
  font-weight: bold;
}

.r1P2za_container .r1P2za_content {
  height: 300px;
  width: 320px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: #ffffff56;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 60px;
  display: flex;
}

.r1P2za_container .r1P2za_buttons {
  width: 200px;
  height: 50px;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px 50px;
  display: flex;
}

.r1P2za_container .r1P2za_buttons .r1P2za_button {
  height: 50px;
}

@media (max-width: 480px) {
  .r1P2za_container .r1P2za_titleContainer .r1P2za_title {
    font-size: 24px;
  }

  .r1P2za_container .r1P2za_content {
    width: 340px;
    padding: 0;
  }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient( to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55 );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  width: 20px;
  flex-shrink: 0;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: Galano Grotesque;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

:root {
  --color-primary: #6c30cb;
  --color-secondary: #595ec7;
  --color-terciary: #00c3a5;
  --color-error: #ef5350;
  --color-warning: #ff9800;
  --color-info: #03a9f4;
  --color-success: #4caf50;
  --color-primary-darker: #15183a;
  --bg-main: #eef1f5;
  --color-gradient-1: linear-gradient( 90deg, #353485 0%, #6e3a8e 35%, #68afe1 100% );
  --color-gradient-2: linear-gradient( to top right, #262657 5%, #713a8d 50%, #55abdf 100% );
  --color-gradient-3: linear-gradient( to left right, #262657 10%, #55abdf 50%, #262657 100% );
  --color-gradient-4: linear-gradient(to top, #595ec7, #68afe1, #595ec7);
  --disabled-color: linear-gradient(#afabab, #afabab);
  --color-white: white;
  --color-black: black;
  --color-gray: #b7b7b7;
  --color-gray-low: #ccc;
}

@font-face {
  font-family: Galano Grotesque Light;
  src: local(Galano Grotesque Light), url("GalanoGrotesqueLight.893b51c6.otf") format("opentype");
}

@font-face {
  font-family: Galano Grotesque Medium;
  src: local(Galano Grotesque Medium), url("GalanoGrotesqueMedium.9c1ec055.otf") format("opentype");
}

@font-face {
  font-family: Galano Grotesque SemiBold;
  src: local(Galano Grotesque SemiBold), url("GalanoGrotesqueSemiBold.2d9f8aa9.otf") format("opentype");
}

@font-face {
  font-family: Galano Grotesque;
  src: local(Galano Grotesque), url("GalanoGrotesqueRegular.22d7c8f2.otf") format("opentype");
}

@font-face {
  font-family: Galano Grotesque Light;
  src: local(Galano Grotesque Light), url("GalanoGrotesqueExtraLight.fe156ce9.otf") format("opentype");
}

:root {
  --nav-height: 10vh;
  --body-height: 90vh;
  --footer-height: 7vh;
  --font-small: 12px;
  --font-normal: 16px;
  --font-medium: 20px;
  --font-large: 25px;
  --font-extra-large: 30px;
  --font-x-extra-large: 40px;
  --border-radius-small: 10px;
  --border-radius-normal: 15px;
  --border-radius-medium: 20px;
  --border-radius-large: 30px;
  --border-radius-larger: 40px;
  --border-radius-extra-large: 50px;
  --z-level-1: 5;
  --z-level-2: 10;
  --z-level-3: 15;
  --z-level-4: 20;
  --z-level-5: 25;
}

/*# sourceMappingURL=index.9156cc33.css.map */
