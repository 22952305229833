@font-face {
    font-family: 'Galano Grotesque Light';
    src: local('Galano Grotesque Light'),
        url(../assets/fonts/GalanoGrotesqueLight.otf) format('opentype');
}

@font-face {
    font-family: 'Galano Grotesque Medium';
    src: local('Galano Grotesque Medium'),
        url(../assets/fonts/GalanoGrotesqueMedium.otf) format('opentype');
}

@font-face {
    font-family: 'Galano Grotesque SemiBold';
    src: local('Galano Grotesque SemiBold'),
        url(../assets/fonts/GalanoGrotesqueSemiBold.otf) format('opentype');
}

@font-face {
    font-family: 'Galano Grotesque';
    src: local('Galano Grotesque'),
        url(../assets/fonts/GalanoGrotesqueRegular.otf) format('opentype');
}
@font-face {
    font-family: 'Galano Grotesque Light';
    src: local('Galano Grotesque Light'),
        url(../assets/fonts/GalanoGrotesqueExtraLight.otf) format('opentype');
}
