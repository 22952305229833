$mobile: 480px;
$tabletPortrait: 767px;
$tabletLandscape: 1024px;
$smallDesktop: 1200px;
$desktop: 1400px;

@mixin breakpoint($media) {
    @if $media == mobile {
        @media (max-width: $mobile) {
            @content;
        }
    } @else if $media == smallTablet {
        @media (max-width: $tabletPortrait) {
            @content;
        }
    } @else if $media == tablet {
        @media (min-width: $tabletPortrait) and (max-width: $tabletLandscape) {
            @content;
        }
    } @else if $media == lessTablet {
        @media (max-width: $smallDesktop) {
            @content;
        }
    } @else if $media == smallDesktop {
        @media (max-width: $desktop) {
            @content;
        }
    } @else if $media == desktop {
        @media (min-width: $desktop) {
            @content;
        }
    }
}
